// from incluso, to escluso

export default [
  {
    "code": "Promo Frutta Secca",
    "percent": 15,
    "categories": ["EC81303"],
    "minProducts": 3,
    "from": "2024-09-04",
    "to": "2024-09-23"
  },
  {
    "code": "Promo Viso",
    "percent": 15,
    "categories": ["EC72200"],
    "minProducts": 2,
    "from": "2024-09-11",
    "to": "2024-09-30"
  },
]

